export const MENUS = [
    {
        name: 'home',
        text: '首页',
        route: {
            path: '/',
        },
        // 匹配的高亮的路由名称 - 值是路由里的 name 属性集合
        channel: 'home',
    },
    {
        name: 'news',
        text: '出海资讯',
        route: {
            path: '/news',
        },
        channel: 'news',
    },
    {
        name: 'solution',
        text: '解决方案',
        channel: 'solution',
        children: [
            // {
            //     name: 'common',
            //     text: '起航版',
            //     route: {
            //         path: '/solution/common',
            //     },
            // },
            {
                name: 'station',
                text: '独立站版',
                route: {
                    path: '/solution/station',
                },
            },
            // {
            //     name: 'senior',
            //     text: '海外广告',
            //     route: {
            //         path: '/solution/senior',
            //     },
            // },
        ],
    },
    // 模块下线 - 20240130 pm: liyingzhou
    // {
    //     name: 'partner',
    //     text: '合作伙伴',
    //     route: {
    //         path: '/partner',
    //     },
    //     channel: 'partner',
    // },
    {
        name: 'subject',
        text: '跨境导航',
        route: {
            path: '/subject',
        },
        channel: 'subject',
    },

    // 资讯页设计稿显示没有此选项，暂时注释掉
    // {
    //     name: 'joinus',
    //     text: '立即入驻',
    //     route: {
    //         path: '/',
    //         hash: '#joinus',
    //     },
    //     channel: '',
    // },
];

export const FOOTER_MENUS = [
    {
        text: '服务条款',
        url: '/agrt/service',
    },
    {
        text: '隐私政策',
        url: '/agrt/privacy',
    },
];

export const FRIEND_LINKS = [
    {
        text: '爱采购',
        url: 'https://b2b.baidu.com/?from=pc_pinzhuan&crm=pc_pinzhuan&refer=1000001',
    },
    {
        text: '爱企查',
        url: 'https://aiqicha.baidu.com/?from=pz',
    },
    {
        text: '加盟星',
        url: 'https://jiameng.baidu.com/?from=pz',
    },
];
