import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { FOOTER_MENUS, FRIEND_LINKS } from './configs';
export default defineComponent({
  name: 'TFooter',
  setup() {
    const footerMenus = FOOTER_MENUS;
    const route = useRoute();
    const adaptWise = computed(() => {
      return route.meta.adaptWise;
    });
    const friendLink = FRIEND_LINKS;
    return {
      footerMenus,
      adaptWise,
      friendLink
    };
  }
});