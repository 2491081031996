import { computed, defineComponent, inject, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { initUcCommonLogin, showUcCommonLoginDialog } from '@baidu/trade-util/utils/login';
import { TBmsUserMenu } from '@baidu/trade-ui';
import TFooter from './Footer.vue';
import TMenu from './Menu.vue';
import LOGO_IMG_BLUE from '@/assets/img/new-logo-blue.png';
import LOGO_IMG from '@/assets/img/new-logo-white.png';
import { PASSPORT_ACCOUNT_STATUS_INJECT_KEY, USE_LOGIN_INJECT_KEY } from '@/base/hooks/useLogin';
import { useScroll } from '@/base/hooks/useScroll';
import { IS_NODE } from '@/base/utils';
import { scLog } from '@/base/log/sclog';
// import {IToTrialParam, TRIAL_TYPE_ENUM} from '@/views/components/Freetrial/types';
// import {INJECT_KEY__TO_TRIAL} from '@/views/components/Freetrial/injectKeys';
const threshold = 5;
/* eslint-disable-next-line */
export default defineComponent({
  name: 'TLayout',
  components: {
    TMenu,
    TFooter,
    TBmsUserMenu
  },
  props: {
    // 是否显示白色背景色
    isWhiteBg: {
      type: Boolean,
      default: false
    },
    // 整体框架布局是否留出头部位置
    isHeaderPadding: {
      type: Boolean,
      default: false
    },
    // wise布局头部大小
    wiseHeaderSize: {
      type: String,
      default: 'default'
    },
    isCustomeHeaderCenter: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    var _route$meta;
    const isProd = process.env.APP_MODE === 'production' || process.env.APP_MODE === 'online' || process.env.APP_MODE === 'preonline';
    const router = useRouter();
    const route = useRoute();
    const scroll = IS_NODE ? undefined : useScroll(ref(window));
    const userState = inject(USE_LOGIN_INJECT_KEY);
    const isPassportAccount = inject(PASSPORT_ACCOUNT_STATUS_INJECT_KEY);
    // const isTrialing = inject(IS_TRIALING_INJECT_KEY);
    const bgFilter = computed(() => scroll && scroll.y.value > threshold);
    const whiteBgVisibled = computed(() => bgFilter.value
    // 以router中meta配置为准，若meta未配置读取props传参
    || (typeof route.meta.isWhiteBgHeader === 'boolean' ? route.meta.isWhiteBgHeader : props.isWhiteBg));
    // 以router中meta配置为准，若meta未配置读取props传参
    const hasHeaderPadding = computed(() => typeof route.meta.isHeaderPadding === 'boolean' ? route.meta.isHeaderPadding : props.isHeaderPadding);
    const logoImg = computed(() => route.meta.isBlueLog || whiteBgVisibled.value ? LOGO_IMG_BLUE : LOGO_IMG);
    const isHeaderFontColorBlack = computed(() => {
      return route.meta.isHeaderFontColorBlack;
    });
    const adaptWise = computed(() => {
      return route.meta.adaptWise;
    });
    const myCustomizeList = computed(() => {
      var _userState$value$deta;
      return userState !== null && userState !== void 0 && (_userState$value$deta = userState.value.detail) !== null && _userState$value$deta !== void 0 && _userState$value$deta.visit_wallet ? [{
        text: '我的钱包',
        href: '/mall/wallet/index'
      }] : [];
    });
    const loginVisible = computed(() => {
      if (route.meta.hideLogin) {
        return false;
      }
      return true;
    });
    // 某些页面不需要默认的 pv 统计 (一般这种情况是会有一些特殊的上报参数，需要在页面中手动埋点上报)
    if (!((_route$meta = route.meta) !== null && _route$meta !== void 0 && _route$meta.isBlockPvLog)) {
      scLog('pv');
    }
    const initCommonLogin = async () => {
      await initUcCommonLogin(isProd, {
        multiLogin: 1,
        isLayer: 1,
        container: 'login',
        defaultLogin: 'passport',
        ucTitle: '百度营销账号'
      });
      showUcCommonLoginDialog();
    };
    /**
     * 登录 或 登录后申请试用
     */
    // const toTrial = inject(INJECT_KEY__TO_TRIAL);
    // function handleToFreeTrial(trigger?: 'loginEntry') {
    //     scLog('click', {
    //         elem: 'home-trial-loginEntry-menu-click',
    //     });
    //     const param: IToTrialParam = {
    //         type: TRIAL_TYPE_ENUM.trial,
    //     };
    //     if (trigger) {
    //         param.trigger = trigger;
    //     }
    //     // if (toTrial) {
    //     //     toTrial(param);
    //     // }
    // }
    return {
      isProd,
      isPassportAccount,
      // isTrialing,
      bgFilter,
      whiteBgVisibled,
      hasHeaderPadding,
      logoImg,
      scroll,
      router,
      route,
      userState,
      isHeaderFontColorBlack,
      adaptWise,
      myCustomizeList,
      initCommonLogin,
      // handleToFreeTrial,
      loginVisible
    };
  }
});