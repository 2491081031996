import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-281a300e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "trade-header-content"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 2,
  class: "trade-header-account__wrapper"
};
const _hoisted_4 = {
  key: 0,
  class: "trade-header-filter"
};
const _hoisted_5 = {
  class: "trade-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_t_menu = _resolveComponent("t-menu");
  const _component_t_bms_user_menu = _resolveComponent("t-bms-user-menu");
  const _component_client_only = _resolveComponent("client-only");
  const _component_t_footer = _resolveComponent("t-footer");
  return _openBlock(), _createElementBlock("main", {
    class: _normalizeClass(['trade-main-layout', _ctx.hasHeaderPadding && 'is-header-padding', _ctx.adaptWise && 'is-layout-adapt-wise', `trade-main-layout-header-${_ctx.wiseHeaderSize}`])
  }, [_createElementVNode("header", {
    class: _normalizeClass(['trade-header', _ctx.whiteBgVisibled && 'is-white-bg'])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_router_link, {
    to: "/"
  }, {
    default: _withCtx(() => [_createElementVNode("img", {
      class: "logo",
      src: _ctx.logoImg,
      alt: "logo"
    }, null, 8, _hoisted_2)]),
    _: 1
  }), _ctx.isCustomeHeaderCenter ? _renderSlot(_ctx.$slots, "header-center", {
    key: 0
  }, undefined, true) : (_openBlock(), _createBlock(_component_t_menu, {
    key: 1,
    class: _normalizeClass([_ctx.whiteBgVisibled && 'is-white-bg', _ctx.isHeaderFontColorBlack && 'is-black-font'])
  }, null, 8, ["class"])), _ctx.loginVisible ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_client_only, null, {
    default: _withCtx(() => {
      var _ctx$userState$isLogi, _ctx$userState, _ctx$userState2, _ctx$userState$detail, _ctx$userState3, _ctx$userState$detail2, _ctx$userState4, _ctx$userState5;
      return [_createVNode(_component_t_bms_user_menu, {
        class: _normalizeClass({
          'is-white-font': !_ctx.whiteBgVisibled
        }),
        "is-login": (_ctx$userState$isLogi = (_ctx$userState = _ctx.userState) === null || _ctx$userState === void 0 ? void 0 : _ctx$userState.isLogin) !== null && _ctx$userState$isLogi !== void 0 ? _ctx$userState$isLogi : false,
        name: (_ctx$userState2 = _ctx.userState) === null || _ctx$userState2 === void 0 ? void 0 : _ctx$userState2.uname,
        "has-saas": (_ctx$userState$detail = (_ctx$userState3 = _ctx.userState) === null || _ctx$userState3 === void 0 || (_ctx$userState3 = _ctx$userState3.detail) === null || _ctx$userState3 === void 0 ? void 0 : _ctx$userState3.business_exists) !== null && _ctx$userState$detail !== void 0 ? _ctx$userState$detail : false,
        "uncompleted-count": (_ctx$userState$detail2 = (_ctx$userState4 = _ctx.userState) === null || _ctx$userState4 === void 0 || (_ctx$userState4 = _ctx$userState4.detail) === null || _ctx$userState4 === void 0 ? void 0 : _ctx$userState4.no_completed_count) !== null && _ctx$userState$detail2 !== void 0 ? _ctx$userState$detail2 : 0,
        "uc-common-config": {
          isProd: _ctx.isProd,
          appid: 701,
          product: 'overseadia'
        },
        "login-type": (_ctx$userState5 = _ctx.userState) === null || _ctx$userState5 === void 0 ? void 0 : _ctx$userState5.loginType,
        "my-customize-link": _ctx.myCustomizeList,
        "is-free-account": _ctx.isPassportAccount
      }, null, 8, ["class", "is-login", "name", "has-saas", "uncompleted-count", "uc-common-config", "login-type", "my-customize-link", "is-free-account"])];
    }),
    _: 1
  })])) : _createCommentVNode("", true)]), _createVNode(_Transition, {
    name: "fade-header-mask"
  }, {
    default: _withCtx(() => [_ctx.whiteBgVisibled ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true)]),
    _: 1
  })], 2), _createElementVNode("section", _hoisted_5, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), !_ctx.route.meta.noFooter ? (_openBlock(), _createBlock(_component_t_footer, {
    key: 0
  })) : _createCommentVNode("", true)], 2);
}