import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { MENUS } from './configs';
import ClientOnly from '@/components/ClientOnly/index.vue';
const AIBOT_URL = 'https://b2b.baidu.com/aibot?fid=671154177';
export default defineComponent({
  name: 'TMenu',
  components: {
    ClientOnly
  },
  setup() {
    const route = useRoute();
    const menus = MENUS;
    const activeIndex = route.path;
    // const handleClick = (menu: typeof MENUS[0]) => {
    //     router.push(menu?.route);
    // };
    const adaptWise = computed(() => {
      return route.meta.adaptWise;
    });
    const handleJumpToAibot = () => {
      window.open(AIBOT_URL, '_blank');
    };
    return {
      route,
      menus,
      // handleClick,
      adaptWise,
      activeIndex,
      handleJumpToAibot
    };
  }
});